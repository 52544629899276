var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 clickable border-all",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.missions,"items-per-page":_vm.pagination.per_page,"item-class":_vm.row_class,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.type))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.mission_active)?_c('v-chip',{attrs:{"label":"","color":_vm.$t('missions.fields.pivot.statuses.' + item.status + '.color')}},[_vm._v(" "+_vm._s(_vm.$t('missions.fields.pivot.statuses.' + item.status + '.label'))+" ")]):_c('div',{staticClass:"py-2"},[_c('v-chip',{attrs:{"label":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('missions.status.inactive'))),_c('br')]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('missions.fields.end'))+": "+_vm._s(_vm.$parseDate(item.mission_end_at))+" ")],1)]}},{key:"item.add_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.add_at))+" ")]}},{key:"item.view_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.view_at))+" ")]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.start_at))+" ")]}},{key:"item.complete_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.complete_at))+" ")]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"info"},on:{"click":function($event){return _vm.updateUserMission(_vm.user.id,item.id,{action:'COMPLETE'})}}},[_vm._v("COMPLETE")]),_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"danger"},on:{"click":function($event){return _vm.updateUserMission(_vm.user.id,item.id,{action:'REMOVE'})}}},[_vm._v("REMOVE")]),_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"success"},on:{"click":function($event){return _vm.updateUserMission(_vm.user.id,item.id,{action:'RESET'})}}},[_vm._v("RESET")])]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.loading},on:{"input":function($event){return _vm.getMissions()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }