<template>
    <v-sheet class="pa-5">

        <users-list></users-list>

    </v-sheet>

</template>

<script>
    import List from "@/components/users/List";
    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'users-list': List,
        }
    }
</script>

<style lang="scss" scoped>
</style>
