<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('users.profile.actions.manage_country') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text--primary">
          {{ $t('users.manage_country.title') }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-divider></v-divider>

          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col>
                <p v-html="$t('users.manage_country.subtitle', {country: actualCountry})">
                </p>
                <v-select
                 :label="$t('users.manage_country.label')"
                  :items="countries"
                  v-model="country"
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="default"
              text
              class="border-all"
              @click="close"
              :loading="isLoading"
          >
            {{ $t('commons.actions.cancel') }}
          </v-btn>
          <v-btn
              color="success"
              text
              class="border-all"
              @click="manageCountry"
              :loading="isLoading"
              :disabled="this.country == this.actualCountry"
          >
            {{ $t('users.manage_country.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ManageCountry",

  props: {
    actualCountry: {
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      country: null,
      valid: false,
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user/loadingState',
      countries: 'consts/getCountries'
    }),
  },
  mounted(){
    this.country = this.actualCountry;
  },
  methods: {
    async manageCountry() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        var data = {
          user_id: this.$route.params.id,
          country: this.country
        }

        await this.$store.dispatch('user/manageCountry', data)
        .then(() => {
              this.close()
              window.location.refresh
        })
        console.log(data)
      }
    },

    close() {
      this.dialog = false
      this.points = 0
    }
  }
}
</script>

<style scoped>

</style>