<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="danger lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('users.profile.actions.delete') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text--danger">
          {{ $t('users.delete.title') }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="default"
              text
              class="border-all"
              @click="close"
              :loading="isLoading"
          >
            {{ $t('commons.actions.cancel') }}
          </v-btn>
          <v-btn
              color="success"
              text
              class="border-all"
              @click="deleteUser"
              :loading="isLoading"
          >
            {{ $t('users.delete.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ManageCountry",

  props: {
    userId: {
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      valid: false,
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user/loadingState',
    }),
  },
  
  methods: {
    async deleteUser() {
        var conf = confirm('DAVVVERO VUOI ELIMINA LUTENTE???');
        if(conf){
          console.log("DELETEING "+this.userId)
          await this.$store.dispatch('user/deleteUser', this.userId)
          .then(() => {
                this.close()
                this.$router.push({ name: 'users' })
          })
        }
        this.close()
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>