var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 clickable border-all",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.receipts,"items-per-page":_vm.pagination.per_page,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency', item.country))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.created_at))+" ")]}},{key:"item.modifier",fn:function(ref){
var item = ref.item;
return [(item.modifier_id)?_c('span',[_c('b',[_vm._v("#"+_vm._s(item.modifier_id))]),_vm._v(" - point: "+_vm._s(item.modifier_points)+" ")]):_vm._e()]}},{key:"item.processed_by",fn:function(ref){
var item = ref.item;
return [(item.processed_by_dynamics)?_c('span',[_vm._v("DYNAMICS")]):_c('span',[_vm._v("QRCODE")])]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.loading},on:{"input":function($event){return _vm.getReceipts()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }