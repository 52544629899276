<template>
  <div>
    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="coupons"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
    >
      <template v-slot:item.title="{ item }">
        <strong>{{ item.title }}</strong><br>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
            label
            :color="(item.status !== 'start' && item.status !== 'complete' && user.points >= item.points) ? $t('coupons.fields.pivot.statuses.usable.color') : $t('coupons.fields.pivot.statuses.' + item.status + '.color')"
            :class="{'mt-2' : item.status === 'start'}"
        >
          {{ (item.status !== 'start' && item.status !== 'complete' && user.points >= item.points) ? $t('coupons.fields.pivot.statuses.usable.label') : $t('coupons.fields.pivot.statuses.' + item.status + '.label') }}
        {{item.status}}</v-chip>
        <br>
        <small v-if="item.status === 'start'">{{$parseDateTime(item.start_at)}}</small>
      </template>

      <template v-slot:item.update="{ item }">
        <v-btn v-if="item.status == 'active'" outlined block class="my-3" small @click="updateUserCoupon(user.id,item.id,{action:'START'})" color="info">START</v-btn>
        <v-btn outlined block class="my-3" small @click="updateUserCoupon(user.id,item.id,{action:'REMOVE'})" color="danger">REMOVE</v-btn>
        <v-btn outlined block class="my-3" small @click="updateUserCoupon(user.id,item.id,{action:'RESET'})" color="success">RESET</v-btn>
      </template>

      <template v-slot:item.add_at="{ item }">
        {{ $parseDateTime(item.add_at)}}
      </template>

      <template v-slot:item.complete_at="{ item }">
        {{ $parseDateTime(item.complete_at)}}
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getCoupons()"
          :disabled="loading"
      ></v-pagination>
    </div>

  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "TableCoupons",

    props: {
      userId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        headers: [
          {text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
          {text: this.$t('commons.fields.title'), value: 'title', sortable: false},
          {text: this.$t('commons.fields.points'), value: 'points', sortable: false, width: '10%', align:'center'},
          {text: this.$t('coupons.fields.add_at'), value: 'add_at', sortable: false, width: '18%', align:'center'},
          {text: this.$t('coupons.fields.complete_at'), value: 'complete_at', sortable: false, width: '18%', align:'center'},
          {text: this.$t('commons.fields.status'), value: 'status', sortable: false, width: '10%', align:'center'},
          {text: this.$t('commons.actions.update'), value: 'update', sortable: false, width: '10%', align:'center'},
        ]
      }
    },

    computed: {
      ...mapGetters({
        loading: 'user/loadingCouponsState',
        user: 'user/getUser',
        coupons: 'user/getUserCoupons',
        pagination: 'user/getUserCouponsPagination'
      }),
    },

    methods: {
      getCoupons() {
        this.$store.dispatch('user/fetchUserCoupons', this.userId)
      },
      updateUserCoupon(user_id,coupon_id,action){
            var data = {user_id:user_id,coupon_id:coupon_id,action:action};
            console.log(data)

            this.$store.dispatch('user/updateUserCoupon', data).then(()=>{
              this.getCoupons()
            })

      }
    }
  }
</script>

<style scoped>

</style>