<template>
  <v-card>

    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="users"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        @click:row="details"
        disable-pagination
    >

      <template v-slot:item.avatar="{ item }">
        <v-avatar size="50" class="my-2">
          <v-img
              v-if="item.avatar"
              :src="item.avatar.image.ios.x3"
              max-width="50"
              contain
          />
        </v-avatar>

      </template>

      <template v-slot:item.created_at="{ item }">
        {{ $parseDate(item.created_at) }}
      </template>

      <template v-slot:item.points="{ item }">
        {{ item.points.points }} / {{ item.points.total_point }}
      </template>

      <template v-slot:item.is_tester="{ item }">
        <b>
          <check :val="item.is_tester"></check>
        </b>
      </template>

      <template v-slot:item.device="{ item }">
        <device :device="item.device" :label="false"/>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-btn class="v-pagination__navigation"
             light
             small
             :disabled="!links.prev || isLoading"
             @click="getUsers(links.prev)"
      >
        <v-icon light>mdi-chevron-left</v-icon>
      </v-btn>

        <v-btn class="v-pagination__navigation"
               light
               small
               :disabled="!links.next || isLoading"
               @click="getUsers(links.next)"
        >
          <v-icon light>mdi-chevron-right</v-icon>
        </v-btn>
    </div>

    <json-debug :objs="[{users: users}]"></json-debug>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import Device from "@/components/users/_partials/Device"
import Check from "@/components/commons/Check";

export default {
  name: "List",

  data() {
    return {
      search: '',
      filterMenu: false,
      filters: {
        exact: {},
        like: {}
      },
      headers: [
        {text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
        // {text: this.$t('users.fields.avatar'), value: 'avatar', sortable: false, width: '5%', align: 'center'},
        {text: this.$t('users.fields.country'), value: 'country', sortable: false, width: '5%', align: 'center'},
        {text: this.$t('users.fields.email'), value: 'email', sortable: false},
        {
          text: this.$t('users.profile.signup_date'),
          value: 'created_at',
          sortable: false,
          width: '15%',
          align: 'center'
        },
        {text: this.$t('users.fields.points'), value: 'points', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('users.fields.is_tester'), value: 'is_tester', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('users.fields.device'), value: 'device', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('users.fields.app_version'), value: 'app_version', sortable: false, width: '10%', align: 'center'},
      ]
    }
  },

  components: {
    Device,
    Check
  },

  mounted() {
    this.reset()
    this.getUsers()
  },

  methods: {
    reset() {
      this.$store.dispatch('user/reset')
    },

    getUsers(url) {
      console.log('getUsers')
      this.$store.dispatch('user/fetchUsers', {
            link: url,
            email: this.search
          }
      )
    },

    details(v) {
      console.log('go ', v)
      this.$router.push({
        name: 'user-profile',
        params: {
          id: v.id
        }
      })
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'user/loadingState',
      users: 'user/getUsers',
      pagination: 'user/getPagination',
      links: 'user/getLinks',
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('user/setLoadingStatus', v)
      }
    },
  },

  watch: {
    search(value) {
      if (!value || value.length < 3) {
        return
      }
      this.getUsers()
    }
  }
}
</script>

<style scoped>

</style>
