<template>
  <div>
    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="receipts"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
    >

      <template v-slot:item.amount="{ item }">
        {{ $n(item.amount, 'currency', item.country) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ $parseDateTime(item.created_at)}}
      </template>

      <template v-slot:item.modifier="{ item }">
        <span v-if="item.modifier_id">
        <b>#{{item.modifier_id}}</b> - point: {{item.modifier_points}}
        </span>
      </template>


      <template v-slot:item.processed_by="{ item }">
        <span v-if="item.processed_by_dynamics">DYNAMICS</span>
        <span v-else>QRCODE</span>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getReceipts()"
          :disabled="loading"
      ></v-pagination>
    </div>

  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "TableReceipts",

    props: {
      userId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        headers: [
          // {text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
          {text: this.$t('receipts.fields.track_code'), value: 'track_code', sortable: false},
          {text: this.$t('receipts.fields.amount'), value: 'amount', sortable: false, width: '10%', align:'center'},
          {text: this.$t('receipts.fields.currency'), value: 'currency', sortable: false, width: '5%', align:'center'},
          {text: this.$t('commons.fields.points'), value: 'points', sortable: false, width: '10%', align:'center'},
          {text: this.$t('receipts.fields.processed_by'), value: 'processed_by', sortable: false, width: '10%', align:'center'},
          {text: this.$t('receipts.fields.modifier'), value: 'modifier', sortable: false, width: '10%', align:'center'},
          {text: this.$t('receipts.fields.created_at'), value: 'created_at', sortable: false, width: '15%', align:'center'},
        ]
      }
    },

    computed: {
      ...mapGetters({
        loading: 'user/loadingReceiptsState',
        user: 'user/getUser',
        receipts: 'user/getUserReceipts',
        pagination: 'user/getUserReceiptsPagination'
      }),
    },

    methods: {
      getReceipts() {
        this.$store.dispatch('user/fetchUserReceipts', this.userId)
      },
    }
  }
</script>

<style scoped>

</style>