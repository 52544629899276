<template>
  <div>
    <v-alert color="success" class="mt-10 font-weight-bold white--text" v-if="user.is_tester">
      BETA TESTER<br>
      L'utente ha accesso a Coupon e Missioni in fase di testing
    </v-alert>

    <v-card>
      <v-card-title>
        {{ $t('users.profile.title') }}
      </v-card-title>
      <v-card-text>

        <overlay-loader
            :is-loading="isLoading"
        ></overlay-loader>

        <profile></profile>

      </v-card-text>
    </v-card>

    <v-alert color="warning" class="mt-10 font-weight-bold" v-if="user.deleted_at">
      Account eliminato in data {{ $parseDateTime(user.deleted_at) }}
      <v-spacer></v-spacer>
      <restore-user v-if="!isLoading" :userId="user.id" class="mr-10">
      </restore-user>
    </v-alert>

    <template v-else>
      <v-card class="mt-10" v-if="canManage || isAdmin || isAnalytics">
        <v-card-title>
          {{ $t('users.profile.actions.title') }}
        </v-card-title>
        <v-card-text class="d-flex justify-start">
          <manage-points v-if="!isLoading" :actual-points="user.points" class="mr-10">
          </manage-points>
          <manage-country v-if="!isLoading" :actual-country="user.country" class="mr-10">
          </manage-country>
          <delete-user v-if="!isLoading" :userId="user.id" class="mr-10">
          </delete-user>
          <manage-is-tester v-if="isAdmin && !isLoading && user.id" :is-tester.sync="user.is_tester"></manage-is-tester>
        </v-card-text>
      </v-card>
    </template>

    <v-card v-if="!isLoading" class="mt-10">
      <v-card-text class="px-5">
        <v-tabs
            v-model="tab"
            centered
            grow
            color="primary"
        >
          <v-tab>
            {{ $t('users.profile.missions') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.coupons') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.used_coupons') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.receipts') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.orders') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.invited') }}
          </v-tab>
          <v-tab>
            {{ $t('users.profile.crm_backup') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <table-missions
                v-if="user.id"
                :user-id="user.id"
            ></table-missions>
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <table-coupons
                v-if="user.id"
                :user-id="user.id"
            ></table-coupons>
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <table-used-coupons
                v-if="user.id"
                :user-id="user.id"
            ></table-used-coupons>
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <table-receipts
                v-if="user.id"
                :user-id="user.id"
            ></table-receipts>
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <orders v-if="user.id"
                    :has-filters="false"
                    :has-report-download="false"
                    :full-paginated-list="true"
                    :show-title="false"
                    :has-search="false"
                    :filter-for-user="user.id.toString()"
                    :permit-actions="false"
            ></orders>
<!--            <user-orders-->
<!--                v-if="user.id"-->
<!--                :user-id="user.id"-->
<!--            ></user-orders>-->
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <invited
                v-if="user.id"
                :user-id="user.id"
                :code="user.invitation_code"
            ></invited>
          </v-tab-item>

          <v-tab-item
              :transition="false"
              :reverse-transition="false"
          >
            <crm-backup 
                v-if="user.id"
                :user-id="user.id">
            </crm-backup>
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Profile from "@/components/users/Profile";
import TableMissions from "@/components/users/_partials/TableMissions";
import TableCoupons from "@/components/users/_partials/TableCoupons";
import TableUsedCoupons from "@/components/users/_partials/TableUsedCoupons";
import TableReceipts from "@/components/users/_partials/TableReceipts";
import Invited from "@/components/users/_partials/Invited";
import ManagePoints from "@/components/users/_partials/ManagePoints";
import ManageCountry from "@/components/users/_partials/ManageCountry";
import DeleteUser from "@/components/users/_partials/DeleteUser";
import RestoreUser from "@/components/users/_partials/RestoreUser";
import ManageIsTester from "@/components/users/_partials/ManageIsTester";
import CrmBackup from "@/components/users/_partials/CrmBackup";
import List from "@/components/ecommerce/List";

export default {

  data() {
    return {
      tab: 0
    }
  },

  components: {
    Profile,
    ManagePoints,
    TableMissions,
    TableCoupons,
    TableUsedCoupons,
    TableReceipts,
    Invited,
    ManageIsTester,
    'orders': List,
    CrmBackup,
    ManageCountry,
    DeleteUser,
    RestoreUser
  },

  mounted() {
    this.getProfile()
  },

  methods: {
    getProfile() {
      this.$store.dispatch('user/fetchUser', this.$route.params.id)
          .then(() => {
            this.$store.dispatch('user/fetchUserMissions', this.$route.params.id);
          })
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'user/loadingState',
      isLoadingMissions: 'user/loadingMissionsState',
      isLoadingCoupons: 'user/loadingCouponsState',
      isLoadingInvited: 'user/loadingInvitedState',
      isAdmin: 'auth/isAdmin',
      canManage: 'auth/canManageAppData',
      isAnalytics: 'auth/isAnalytics',
      user: 'user/getUser',
      coupons: 'user/getUserCoupons',
      usedCoupons: 'user/getUserUsedCoupons',
      receipts: 'user/getUserReceipts',
      orders: 'user/getUserOrders',
      invited: 'user/getInvited'
    }),
  },

  beforeMount() {
    this.$store.dispatch('user/resetUserData')
  },

  watch: {
    $route() {
      this.tab = 0
      this.$store.dispatch('user/resetUserData')
      this.$store.dispatch('user/fetchUser', this.$route.params.id)
          .then(() => {
            this.$store.dispatch('user/fetchUserMissions', this.$route.params.id);
          })
    },
    tab(tab) {

      if (tab === 1 && Object.keys(this.coupons).length === 0) {
        this.$store.dispatch('user/fetchUserCoupons', this.$route.params.id)
      }

      if (tab === 2 && Object.keys(this.usedCoupons).length === 0) {
        this.$store.dispatch('user/fetchUserUsedCoupons', this.$route.params.id)
      }

      if (tab === 3 && Object.keys(this.receipts).length === 0) {
        this.$store.dispatch('user/fetchUserReceipts', this.$route.params.id)
      }

      // if (tab === 4 && Object.keys(this.orders).length === 0) {
      //   this.$store.dispatch('user/fetchUserOrders', this.$route.params.id)
      // }

      if (tab === 5 && Object.keys(this.invited).length === 0) {
        this.$store.dispatch('user/fetchInvited', {
          user_id: this.user.id,
          code: this.user.invitation_code
        })
      }

    }
  }
}

</script>

<style scoped>

</style>