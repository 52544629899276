<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('users.profile.actions.manage_points') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text--primary">
          {{ $t('users.manage_points.title') }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-divider></v-divider>

          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col>
                <p v-html="$t('users.manage_points.subtitle', {points: actualPoints})">
                </p>
                <v-text-field
                    v-model="points"
                    :label="$t('users.manage_points.point_input_label')"
                    outlined
                    dense
                    type="number"
                    :hint="$t('users.manage_points.point_input_help')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="default"
              text
              class="border-all"
              @click="close"
              :loading="isLoading"
          >
            {{ $t('commons.actions.cancel') }}
          </v-btn>
          <v-btn
              color="success"
              text
              class="border-all"
              @click="managePoints"
              :loading="isLoading"
              :disabled="points == 0"
          >
            {{ $t('users.manage_points.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ManagePoints",

  props: {
    actualPoints: {
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      points: 0,
      valid: false,
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'point/loadingState',
    }),
  },

  methods: {
    async managePoints() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        await this.$store.dispatch('point/manage', {
          user: this.$route.params.id,
          points: this.points
        })
            .then(() => {
              this.close()
            })
      }
    },

    close() {
      this.dialog = false
      this.points = 0
    }
  }
}
</script>

<style scoped>

</style>