<template>
  <div>
    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="invited"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
        disable-pagination
    >
      <template v-slot:item.email="{ item }">
        <strong>{{ item.email }}</strong><br>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ $parseDateTime(item.created_at)}}
      </template>

      <template v-slot:item.email_verified_at="{ item }">
        {{ $parseDateTime(item.email_verified_at)}}
      </template>

    </v-data-table>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Invited",

  props: {
    userId: {
      type: Number,
      required: true
    },
    code: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      headers: [
        {text: this.$t('users.fields.email'), value: 'email', sortable: false},
        {text: this.$t('users.fields.gender'), value: 'gender', sortable: false, width: '8%', align: 'center'},
        {
          text: this.$t('commons.fields.created_at'),
          value: 'created_at',
          sortable: false,
          width: '18%',
          align: 'center'
        },
        {
          text: this.$t('users.fields.email_verified_at'),
          value: 'complete_at',
          sortable: false,
          width: '18%',
          align: 'center'
        },
        {text: this.$t('commons.fields.country'), value: 'country', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('commons.fields.language'), value: 'language', sortable: false, width: '10%', align: 'center'},
      ]
    }
  },

  computed: {
    ...mapGetters({
      loading: 'user/loadingInvitedState',
      user: 'user/getUser',
      invited: 'user/getInvited'
    }),
  },
}

</script>

<style scoped>

</style>