<template>
  <div>
    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="missions"
        :items-per-page="pagination.per_page"
        :item-class= "row_class"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
    >
      <template v-slot:item.title="{ item }">
        <strong>{{ item.title }}</strong><br>
        <small> {{item.type}}</small>
      </template>

      <template v-slot:item.status="{ item }">

        <v-chip
            label
            :color="$t('missions.fields.pivot.statuses.' + item.status + '.color')"
            v-if="item.mission_active"
        >
          {{ $t('missions.fields.pivot.statuses.' + item.status + '.label') }}
        </v-chip>

        <div class="py-2" v-else>
        <v-chip
            label
            color="error"
        >
          {{ $t('missions.status.inactive') }}<br>
        </v-chip><br>
          {{ $t('missions.fields.end') }}: {{$parseDate(item.mission_end_at)}}
        </div>

      </template>

      <template v-slot:item.add_at="{ item }">
        {{ $parseDateTime(item.add_at)}}
      </template>

      <template v-slot:item.view_at="{ item }">
        {{ $parseDateTime(item.view_at)}}
      </template>

      <template v-slot:item.start_at="{ item }">
        {{ $parseDateTime(item.start_at)}}
      </template>

      <template v-slot:item.complete_at="{ item }">
        {{ $parseDateTime(item.complete_at)}}
      </template>

      <template v-slot:item.update="{ item }">
        <v-btn outlined block class="my-3" small @click="updateUserMission(user.id,item.id,{action:'COMPLETE'})" color="info">COMPLETE</v-btn>
        <v-btn outlined block class="my-3" small @click="updateUserMission(user.id,item.id,{action:'REMOVE'})" color="danger">REMOVE</v-btn>
        <v-btn outlined block class="my-3" small @click="updateUserMission(user.id,item.id,{action:'RESET'})" color="success">RESET</v-btn>
      </template>

    </v-data-table>

    <v-divider></v-divider>
    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getMissions()"
          :disabled="loading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "TableMissions",

    props: {
      userId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        headers: [
          {text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
          {text: this.$t('commons.fields.title'), value: 'title', sortable: false, width: '25%'},
          // {text: this.$t('commons.fields.type'), value: 'type', sortable: false},
          {text: this.$t('missions.fields.pivot.add_at'), value: 'add_at', sortable: false, width: '18%', align: 'center'},
          // {text: this.$t('missions.fields.pivot.view_at'), value: 'view_at', sortable: false, width: '15%', align: 'center'},
          {text: this.$t('missions.fields.pivot.start_at'), value: 'start_at', sortable: false, width: '18%', align: 'center'},
          {text: this.$t('missions.fields.pivot.complete_at'), value: 'complete_at', sortable: false, width: '18%', align: 'center'},
          {text: this.$t('commons.fields.status'), value: 'status', sortable: false, width: '10%', align: 'center'},
          {text: this.$t('commons.actions.update'), value: 'update', sortable: false, width: '10%', align:'center'},
        ]
      }
    },

    computed: {
      ...mapGetters({
        loading: 'user/loadingMissionsState',
        user: 'user/getUser',
        missions: 'user/getUserMissions',
        pagination: 'user/getUserMissionsPagination'
      }),
    },

    methods: {
      getMissions() {
        this.$store.dispatch('user/fetchUserMissions', this.userId)
      },
      row_class(item) {
        if (!item.mission_active) {
          return "inactive-mission"; //can also return multiple classes e.g ["orange","disabled"]
        }
      },
      updateUserMission(user_id,mission_id,action){
            var data = {user_id:user_id,mission_id:mission_id,action:action};
            console.log(data)

            this.$store.dispatch('user/updateUserMission', data).then(()=>{
              this.getMissions()
            })

      }
    }
  }
</script>

<style lang="scss">
.inactive-mission {
  td {
    color: #999999 !important;
  }
}
</style>