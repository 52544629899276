<template>
  <span>
    <template v-if="label">{{$t('users.fields.device')}}: </template><v-icon small class="mb-1" :color="(device === 'android') ? 'green' : 'balck'">{{ (device === 'ios') ? 'mdi-apple' : 'mdi-android' }}</v-icon>
  </span>
</template>

<script>
  export default {
    name: "Device",

    props: {
      device: {
        type: String,
        required: true
      },
      label: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>