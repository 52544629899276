<template>
  <v-row>
    <!-- <v-col cols="12"
           sm="12"
           md="3"
           class="text-center"
           v-if="user.avatar"
    >

      <v-avatar size="90%">
        <v-img
            v-if="user.avatar"
            :src="user.avatar.image"
            class="pointer mb-5 pa-10 bordered"
            max-width="250"
            contain
        />
      </v-avatar>

    </v-col> -->

<v-col cols="12"
           sm="12"
           md="3"
           class="text-center"
           v-if="user.qrcode"
    >

        <v-img
            v-if="user.qrcode.img"
            :src="user.qrcode.img.url"
            class="pointer mb-5 mx-auto pa-10 bordered"
            width="250"
            heigh="250"
            contain
        />
        <strong>{{ user.user_code }}</strong>
    </v-col>

    <v-col cols="12"
           sm="6"
           md="3"
           class="border-left"
           v-if="user.profile"
    >
      {{$t('users.fields.profile.firstname')}}: <strong>{{profile.firstname}}</strong><br>
      {{$t('users.fields.profile.lastname')}}: <strong>{{profile.lastname}}</strong><br>
      {{$t('users.fields.profile.gender')}}: <strong>{{profile.gender}}</strong><br>
      {{$t('users.fields.profile.date_of_birth')}}: <strong>{{$parseDate(profile.date_of_birth)}}</strong><br>
      <v-divider class="my-2"></v-divider>
      {{$t('users.fields.profile.phone')}}: <strong>({{profile.phone_prefix}})</strong> {{profile.phone}}<br>
      <v-divider class="my-2"></v-divider>
      {{$t('users.fields.profile.address')}}: <strong>{{profile.address}}</strong> {{profile.street_number}}<br>
      {{$t('users.fields.profile.address_info')}}: <strong>{{profile.address_info}}</strong><br>
      {{$t('users.fields.profile.city')}}: <strong>{{profile.city}}</strong><br>
      {{$t('users.fields.profile.province')}}: <strong>{{profile.province}}</strong><br>
      {{$t('users.fields.profile.zip_code')}}: <strong>{{profile.zip_code}}</strong><br>
      <v-divider class="my-2"></v-divider>
      {{$t('users.fields.profile.store')}}: <strong v-if="profile.store">{{profile.store.name}}</strong><br>
    </v-col>

    <v-col cols="12"
           sm="6"
           md="6"
           class="border-left"
           v-if="user"
    >
      {{$t('commons.fields.id')}} <strong>{{ user.id }}</strong><br>
      {{$t('users.fields.country')}}: <strong>{{ user.country }}</strong><br>
      {{$t('users.fields.email')}}: <strong>{{ user.email }}</strong>
      <template v-if="user.email_verified_at">
        <v-icon color="success">mdi-check</v-icon><br>
      </template>
      <template v-else>
        <v-btn small v-if="!loadingResendEmailVerification" :loading="loadingResendEmailVerification" @click="resendEmailVerification(user.email)" color="primary">RESEND EMAIL Verification</v-btn>
        <v-btn small v-if="loadingResendEmailVerification && messageResendEmailVerification" color="success">{{messageResendEmailVerification}}</v-btn>
        <br>
      </template>

      
      <v-divider class="my-2"></v-divider>
      {{$t('users.fields.status')}}: <strong>{{ user.status }}</strong><br>
      {{$t('users.fields.last_access')}}: <strong>{{ $parseDateTime(user.last_access) }}</strong><br>
      {{$t('users.profile.signup_date')}}: <strong>{{ $parseDate(user.created_at) }}</strong><br>
      {{$t('users.fields.current_points')}}: <strong>{{user.points}}</strong><br>
      {{$t('users.fields.total_points')}}: <strong>{{user.total_points}}</strong><br>
      {{$t('users.fields.notificable')}}: <strong> <v-icon v-if="user.app_token">mdi-check</v-icon> </strong><br>
      <device v-if="user.device" :device="user.device"></device> | {{ user.app_version }} <br>
      
      <template v-if="user.consents">

        <v-divider class="my-2"></v-divider>

        <check
            :label="$t('users.fields.consents.app_rules') + ' at: ' + $parseDate(user.annual_confirm)"
            :val="user.consents.app_rules"
        ></check>

        <check
            :label="$t('users.fields.consents.marketing') + ' at: ' + $parseDate(user.consents.confirmed_at)"
            :val="user.consents.marketing"
        ></check>

        <check
            :label="$t('users.fields.consents.third_party')+ ' at: ' + $parseDate(user.consents.confirmed_at)"
            :val="user.consents.third_party"
        ></check>
      </template>
    </v-col>
<!--    <v-col cols="12"-->
<!--           sm="6"-->
<!--           md="3"-->
<!--           class="border-left"-->
<!--    >-->
<!--      {{$t('users.invited.invited')}}: <strong>{{user.invited}}</strong>-->
<!--    </v-col>-->

    <overlay-loader
        :is-loading="isLoading"
        :absolute="true"
        :opacity="0.7"
    ></overlay-loader>

  </v-row>
</template>

<script>
  import {mapGetters} from "vuex";
  import Check from "@/components/commons/Check";
  import Device from "@/components/users/_partials/Device";

  export default {
    name: "Profile",

    components: {Check, Device},

    computed: {
      ...mapGetters({
        user: 'user/getUser',
        isLoading: 'user/loadingState',
        profile: 'user/getUserProfile',
      }),
    },
    data: () => ({
      loadingResendEmailVerification: false,
      messageResendEmailVerification: null
    }),
    methods:{
      resendEmailVerification(email){
        console.log('resendEmailVerification: ',email)
          this.loadingResendEmailVerification = true
          var axios = require('axios');
          var data = JSON.stringify({
            "email": email
          });

          var base = process.env.VUE_APP_API_URL.replace('/admin','');

          var config = {
            method: 'post',
            url: base+'/resend-email-verification',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'application/json', 
            },
            data : data
          };

          var self = this

          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            self.messageResendEmailVerification = response.data.message
          })
          .catch(function (error) {
            console.log(error);
            self.messageResendEmailVerification = error.response.data.message
          });
        
      }
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}
</style>