var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 clickable border-all",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.coupons,"items-per-page":_vm.pagination.per_page,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title))]),_c('br')]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{'mt-2' : item.status === 'start'},attrs:{"label":"","color":(item.status !== 'start' && item.status !== 'complete' && _vm.user.points >= item.points) ? _vm.$t('coupons.fields.pivot.statuses.usable.color') : _vm.$t('coupons.fields.pivot.statuses.' + item.status + '.color')}},[_vm._v(" "+_vm._s((item.status !== 'start' && item.status !== 'complete' && _vm.user.points >= item.points) ? _vm.$t('coupons.fields.pivot.statuses.usable.label') : _vm.$t('coupons.fields.pivot.statuses.' + item.status + '.label'))+" "+_vm._s(item.status))]),_c('br'),(item.status === 'start')?_c('small',[_vm._v(_vm._s(_vm.$parseDateTime(item.start_at)))]):_vm._e()]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [(item.status == 'active')?_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"info"},on:{"click":function($event){return _vm.updateUserCoupon(_vm.user.id,item.id,{action:'START'})}}},[_vm._v("START")]):_vm._e(),_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"danger"},on:{"click":function($event){return _vm.updateUserCoupon(_vm.user.id,item.id,{action:'REMOVE'})}}},[_vm._v("REMOVE")]),_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","block":"","small":"","color":"success"},on:{"click":function($event){return _vm.updateUserCoupon(_vm.user.id,item.id,{action:'RESET'})}}},[_vm._v("RESET")])]}},{key:"item.add_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.add_at))+" ")]}},{key:"item.complete_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.complete_at))+" ")]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.loading},on:{"input":function($event){return _vm.getCoupons()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }