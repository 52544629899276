<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="success lighten-1"
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('users.profile.actions.beta_tester') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text--primary">
          {{ $t('users.manage_beta_tester.title') }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-divider></v-divider>

          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col>
                <p>
                  Un utente Beta Tester avrà accesso ai Coupon e alle Missioni in fase di testing.
                </p>
                <testing-switch
                    :label="$t('users.fields.is_tester')"
                    :value.sync="betaTester"
                    :has-message="false"
                ></testing-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="default"
              text
              class="border-all"
              @click="close"
              :loading="isLoading"
          >
            {{ $t('commons.actions.cancel') }}
          </v-btn>
          <v-btn
              color="success"
              text
              class="border-all"
              @click="updateUserTesterStatus"
              :loading="isLoading"
          >
            {{ $t('users.manage_beta_tester.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TestingSwitch from "@/components/form/TestingSwitch";

export default {
  name: "ManageIsTester",

  components: {
    TestingSwitch
  },

  data() {
    return {
      dialog: false,
      valid: false,
      betaTester: this.$store.state.user.user.is_tester
    }
  },

  props: {
    isTester: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      isLoading: 'user/loadingState',
    }),
  },

  methods: {
    async updateUserTesterStatus() {
      await this.$store.dispatch('user/patchUserIsTester', {
        user: this.user.id,
        is_tester: this.betaTester
      })
          .then(() => {
            this.close()
          })
    },

    close() {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>