var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.users,"hide-default-footer":true,"disable-pagination":""},on:{"click:row":_vm.details},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2",attrs:{"size":"50"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar.image.ios.x3,"max-width":"50","contain":""}}):_vm._e()],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.created_at))+" ")]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.points.points)+" / "+_vm._s(item.points.total_point)+" ")]}},{key:"item.is_tester",fn:function(ref){
var item = ref.item;
return [_c('b',[_c('check',{attrs:{"val":item.is_tester}})],1)]}},{key:"item.device",fn:function(ref){
var item = ref.item;
return [_c('device',{attrs:{"device":item.device,"label":false}})]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-btn',{staticClass:"v-pagination__navigation",attrs:{"light":"","small":"","disabled":!_vm.links.prev || _vm.isLoading},on:{"click":function($event){return _vm.getUsers(_vm.links.prev)}}},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"v-pagination__navigation",attrs:{"light":"","small":"","disabled":!_vm.links.next || _vm.isLoading},on:{"click":function($event){return _vm.getUsers(_vm.links.next)}}},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-chevron-right")])],1)],1),_c('json-debug',{attrs:{"objs":[{users: _vm.users}]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }