<template>
     <div>
          <v-card :loading="loading">
               <v-card-title primary-title>
                   {{ $t('users.profile.crm_backup') }}
               </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                    <div v-if="backup">
                         <v-simple-table dense>
                              <template v-slot:default>
                                   <tbody>
                                   <tr
                                        v-for="(value,field) in backup"
                                        :key="field"
                                   >
                                        <template v-if="field != 'response' ">
                                        <th class="text-left">
                                        {{field}}
                                        </th>
                                        <td>
                                             {{ value }}
                                        </td>
                                        </template>
                                        <template v-if="field == 'response' ">
                                        <td colspan="2">
                                            <json-debug :objs="[{response: JSON.parse(value)}]"></json-debug>
                                        </td>
                                        </template>
                                   </tr>
                                   </tbody>
                              </template>
                         </v-simple-table>
                    </div>
                    <div v-else>
                         Wait 30 seconds and then click:
                         <v-btn color="info" @click="getBackup">Get BackUp</v-btn>
                    </div>
               </v-card-text>
               <v-card-actions>
                    <v-btn color="info" @click="runBackup">Run BackUp</v-btn>
               </v-card-actions>
          </v-card>
     </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     name:"CrmBackup",
     props:{
          userId: {
               type: Number,
               required: true
          },
     },
     computed: {
      ...mapGetters({
        backup: 'user/getUserCrmBackup',
        loading: 'user/loadingCrmBackup',
      }),
    },
     data(){
          return{
          }
     },
     mounted(){
          this.getBackup();
     },
     methods:{
          async getBackup(){
               this.$store.dispatch('user/fetchCrmBackup', {
                    user_id: this.userId,
               })
          },
          runBackup(){
               //this.loading = true;
               console.log('runBackup',this.userId)
               this.$store.dispatch('user/runCrmBackup', {
                    user_id: this.userId,
               })
          }
     }
}
</script>